<template>
  <div id="section-content" class="container container-fluid">
    <div class="bar-back d-flex justify-content-between align-items-center">
      <div id="top"></div>
      <router-link :to="`/member/lottery/yeeki/${catalogId}`" class="mr-auto" id="yingying"><i class="fas fa-chevron-left"></i> ย้อนกลับ</router-link>
       <a v-b-modal.rule-yeekee href="#" class="btn btn-outline-secondary btn-sm mr-1 text-dark" >กติกา</a>
       <button
          class="tn btn-dark btn-sm btn-numpage active"
          @click="addNumberPage()"
          v-if="!getIsCloseYeekee(dataInfo.timeEnd, 0)"
          ><i class="fas fa-calculator"></i> แทงเลข </button
        >
      <a
        href="#"
        class="btn btn-dark btn-sm btn-numpage d-none active"
        data-id="numpage_2"
        ><i class="fas fa-calculator"></i> ยิงเลข</a
      >
    </div>
    <div class="p-2 w-100 bg-light main-content align-self-stretch" style="min-height: calc((100vh - 179px) - 50px);" v-if="getYeekiInfo.status==4">
        <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-1 pb-0">
          <div class="lotto-title">
            <h4><i class="fas fa-exclamation-triangle"></i>{{ getYeekiInfo.group_name }} - {{ getYeekiInfo.title }}</h4>
          </div>
        </div>
          <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 xtarget col-lotto">
            <div class="text-center my-4">
              <span style="font-size:100px;line-height:1.2;"><i class="fas fa-spinner fa-spin text-danger"></i></span>
                <h4 class="text-danger" style="font-family:inherit"> ยกเลิกรอบเนื่องจากยอดการยิงเลขมีไม่ถึง 20 ลำดับ </h4>
              </div>
          </div>
      </div>
    <div class="p-2 w-100 bg-light main-content align-self-stretch" style="min-height: calc((100vh - 179px) - 50px);" v-else-if="endTime">
      <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-1 pb-0">
        <div class="lotto-title">
          <h4><i class="fas fa-exclamation-triangle"></i> {{ getYeekiInfo.group_name }} - {{ getYeekiInfo.title }}</h4>
        </div>
      </div>
        <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 xtarget col-lotto">
          <div class="text-center my-4">
            <span style="font-size:100px;line-height:1.2;"><i class="fas fa-spinner fa-spin text-danger"></i></span>
              <h4 class="text-danger" style="font-family:inherit"> กำลังรอผลหวย</h4>
            </div>
          <div class="alert alert-danger text-center">กรุณา คลิกที่ปุ่ม <b>Refresh</b> เพื่อทำรายการใหม่อีกครั้ง</div>
        </div>
        <div class="bg-white p-2 rounded shadow-sm w-100 mb-5">
          <router-link :to="`/member/lottery/yeeki-result/${catalogId}/${roundId}`" class="btn btn-dark btn-block text-white">
            <i class="fas fa-sync-alt"></i> Refresh
          </router-link>
        </div>
    </div>
    <div class="p-2 w-100 bg-light main-content align-self-stretch d-flex flex-column align-items-stretch"
      style="min-height: calc((100vh - 150px) - 50px)" v-else>
      <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 px-2 xtarget col-lotto d-flex flex-row mb-0 pb-0">
        <div class="d-flex justify-content-between align-items-center mb-0 flex-fill lotto-title">
          <h4 class="tanghuay-h4">
             {{ getYeekiInfo.group_name }} <span class="badge badge-pill badge-primary font-weight-light"><small>{{ getYeekiInfo.title }}</small></span>
          </h4>
          <div class="tanghuay-time">
            <i class="sn-icon sn-icon--daily2"></i>
            <span class="countdown"
            data-finaldate="1624735800000"
            v-if="dataInfo"
            v-html="dataInfo.timeEnd != null ? calTime(dataInfo.timeEnd, 2) : ''"></span>
          </div>
        </div>
      </div>

      <div class="row-menu-grid" style="min-height: calc((100vh - 242px) - 59px)">
            <div class="bgwhitealpha text-secondary shadow-sm rounded p-2 px-2 mt-1 flex-column mb-5" id="numpage_2">
              <h3 class="text-center"><i class="sn-icon sn-icon--graph2"></i> ผลรวมยี่กี</h3>
              <h3 class="text-center" id="total_ying" v-if="listPush">{{listPush.sum}}</h3>
              <div class="group__keyboard">
                <div class="num-pad box__show-number">
                  <!-- <h4 class="text-center">ระบุตัวเลข 5 หลัก</h4> -->
                  <input placeholder="กรอกตัวเลข 5 หลัก" v-model="number"  maxlength="5" type="text" readonly="readonly" class="form-control form-control-lg text-center show-input-number">
                  <div class="key-pad box__keyboard">
                    <div class="row p-2">
                      <div
                        class="col-3"
                        :key="index"
                        v-for="(number, index) in [
                          1,
                          2,
                          3,
                          'delete',
                          4,
                          5,
                          6,
                          'cancel',
                          7,
                          8,
                          9,
                          'clear',
                          null,
                          0,
                          null,
                          undefined
                        ]"
                      >
                        <button
                          v-if="number === 'cancel'"
                          class="btn btn-secondary btn-block btn-cancel warning btn-cancle-last-add-num"
                          @click="random"
                        >
                          <span>สุ่มเลข</span>
                        </button>
                        <button v-else-if="number === 'clear'" class="btn btn-success btn-block btn-reset" :disabled="hasNumber() || isWait" @click="onSubmit">
                          <span>{{ countDown || 'เพิ่มเลข' }}</span>
                        </button>
                        <button
                          v-else-if="number === 'delete'"
                          class="btn btn-primary btn-block"
                          data-id="delete"
                          @click="deleteLast()"
                        >
                          <i class="fas fa-backspace"></i>
                        </button>
                        <div v-else-if="number === undefined"></div>
                        <button
                          v-else
                          :class="number === null ? 'btn btn-blank' : 'btn btn-outline-primary btn-block'"
                          :disabled="number === null ? true : false"
                          :data-id="number"
                          @click="pushNumber(number)"
                        >
                          {{ number }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="mt-1 mb-2" />
              <div class="d-flex flex-column yeekee__lists-number" style="">
                <h5 class="text-center text-success"><i class="fas fa-th-list"></i> รายชื่อผู้ทายเลข</h5>
                <span id="yresult" v-if="listPush">
                  <div
                  v-for="(item, index) in winLists"
                  :key="index + item.name"
                    class="flex-row justify-content-between align-items-stretch mb-1 d-flex"
                    id="list_ying_0"
                    style="display: none"
                  >
                    <div
                      class="
                        rounded
                        p-2
                        w-25
                        d-flex
                        flex-column
                        justify-content-around
                      "
                      :class="'bg-success text-white text-center'"
                    >
                      <span class="badge badge-pill badge-light "
                      :class="'text-success'"
                        >อันดับที่ <span data-id="ly_ranked">{{index + 1}}</span></span
                      >
                      <h3 class="mb-0"><span data-id="ly_ying">{{item.num}}</span></h3>
                    </div>
                    <div
                      class="
                        border border-success
                        bg-light
                        rounded
                        p-2
                        ml-1
                        flex-fill
                        d-flex
                        flex-column
                        justify-content-around
                      "
                    >
                      <div>
                        <span class="badge badge-pill badge-light text-success"
                          ><i class="fas fa-user-circle"></i> ผู้ส่งเลข</span
                        >
                        <span data-id="ly_send" v-if="item.name">{{item.name.split('').length > 4 ? strReplace(item.name) : item.name}}</span>
                      </div>
                      <hr class="my-1" />
                      <div>
                        <span class="badge badge-pill badge-light text-success"
                          ><i class="fas fa-calendar-check"></i> เมื่อ</span
                        >
                        <span data-id="ly_date">{{item.date}}</span>
                      </div>
                    </div>
                  </div>
                  <div
                  v-for="(item, index) in listPush.lists"
                  :key="index + winLists.length"
                    class="flex-row justify-content-between align-items-stretch mb-1 d-flex"
                    id="list_ying_0"
                    style="display: none"
                  >
                    <div
                      class="
                        rounded
                        p-2
                        w-25
                        d-flex
                        flex-column
                        justify-content-around
                      "
                      :class="index + 1  + winLists.length === 1 ? 'bg-success text-white text-center' : 'bg-secondary text-white text-center border border-secondary'"
                    >
                      <span class="badge badge-pill badge-light "
                      :class="index + 1  + winLists.length === 1 ? 'text-success': ''"
                        >อันดับที่ <span data-id="ly_ranked">{{index + 1  + winLists.length }}</span></span
                      >
                      <h3 class="mb-0"><span data-id="ly_ying">{{item.num}}</span></h3>
                    </div>
                    <div
                      class="
                        border border-success
                        bg-light
                        rounded
                        p-2
                        ml-1
                        flex-fill
                        d-flex
                        flex-column
                        justify-content-around
                      "
                    >
                      <div>
                        <span class="badge badge-pill badge-light text-success"
                          ><i class="fas fa-user-circle"></i> ผู้ส่งเลข</span
                        >
                        <span data-id="ly_send" v-if="item.name">{{item.name.split('').length > 4 ? strReplace(item.name) : item.name}}</span>
                      </div>
                      <hr class="my-1" />
                      <div>
                        <span class="badge badge-pill badge-light text-success"
                          ><i class="fas fa-calendar-check"></i> เมื่อ</span
                        >
                        <span data-id="ly_date">{{item.date}}</span>
                      </div>
                    </div>
                  </div>
                </span>
              </div>
            </div>
      </div>
    </div>
    <app-rule-yeekee-modal />
  </div>
</template>

<script>
import { pushNumberYeekee, listPushNumberYeekee } from '@/services'
import ruleYeekeeModal from '@/components/modals/RuleYeekeeModal'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
export default {
  components: {
    'app-rule-yeekee-modal': ruleYeekeeModal
  },
  async created () {
    this.setLoading(true)
    this.roundId = this.$route.params.id
    this.catalogId = this.$route.params.catalog
    setInterval(() => {
      this.getNow()
    }, 1000)
    try {
      if (this.getYeekiInfo.status === '') {
        await this.getYeekiInfomationByRoundId(this.$route.params.id)
      }
      this.setLoading(true)
      await this.getYeekiRound(this.$route.params.catalog)
      this.dataInfo = this.getSubCatYeekiAviliableList.find(
        (e) => parseInt(e.yeekiRoundID) === parseInt(this.$route.params.id)
      )
      await this.listPushNumber()
      this.setLoading(false)
    } catch (error) {
      this.setLoading(false)
    }
  },
  mounted () {
  },
  data () {
    return {
      number: '',
      type: 5,
      roundId: '',
      catalogId: '',
      dataInfo: {},
      currentDate: '',
      listPush: {},
      winLists: [],
      isWait: false,
      countDown: undefined,
      endTime: false
    }
  },
  computed: {
    ...mapGetters(['getSubCatYeekiAviliableList', 'getYeekiInfo'])
  },
  methods: {
    ...mapActions(['getYeekiInfomationByRoundId', 'setLoading', 'getYeekiRound']),
    strReplace (str) {
      return str.replace(str.substring(2, str.split('').length - 2), '***')
    },
    waitingTimeForNew () {
      const time = 15
      this.countDown = time
      this.isWait = true
      const timer = setInterval(() => {
        this.countDown -= 1
        if (this.countDown < 0) {
          clearInterval(timer)
          this.countDown = undefined
          this.isWait = false
        }
      }, 1000)
      setTimeout(() => {
        clearInterval(timer)
        this.countDown = undefined
        this.isWait = false
      }, 15000)
    },
    async listPushNumber () {
      const resp = await listPushNumberYeekee({ roundID: parseInt(this.roundId), page: 1 })
      if (resp.status_code === 'SUCCESS') {
        this.listPush = resp.data
        this.setIndexWin()
      }
    },
    setIndexWin () {
      const indexwin = this.listPush.index_win
      this.winLists = this.listPush.lists.filter((_, i) => indexwin.indexOf(i) !== -1)
    },
    getNow () {
      this.currentDate = moment()
    },
    hasNumber () {
      return this.number.split('').length < 5
    },
    calTime (valueX, addMin) {
      const value = moment(valueX).add(addMin, 'minutes')
      const now = this.currentDate ? this.currentDate : ''
      const timeleft = value - now
      if (timeleft > 0) {
        const hours = Math.floor(
          (timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        )
        const minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60))
        const seconds = Math.floor((timeleft % (1000 * 60)) / 1000)
        return `${hours}<small>:</small>${minutes}<small>:</small>${seconds}`
      } else {
        if (value != null && value !== '') {
          this.endTime = true
        }
        return '00<small>:</small>00<small>:</small>00'
      }
    },
    getIsCloseYeekee (currentX, min) {
      const now = this.currentDate !== null ? this.currentDate : ''
      const current = moment(currentX).add(min, 'minutes')
      const final = moment(current).diff(now)
      if (final > 0) {
        return false
      } else {
        return true
      }
    },
    pushNumber (numbers) {
      if (this.number.length < 5) {
        this.number = this.number + numbers
      }
    },
    clear () {
      this.number = ''
    },
    random () {
      this.number = `${Math.floor(Math.random() * 90000) + 10000}`
    },
    deleteLast () {
      this.number = this.number.substring(0, this.number.length - 1)
    },
    async onSubmit () {
      try {
        if (this.number.split('').length === 5) {
          this.setLoading(true)
          const resp = await pushNumberYeekee({ roundID: this.roundId, number: this.number })
          if (resp.status_code === 'SUCCESS') {
            this.number = ''
            await this.listPushNumber()
            this.setLoading(false)
          } else {
            await this.listPushNumber()
            this.setLoading(false)
          }
          this.waitingTimeForNew()
        }
      } catch (error) {
        this.waitingTimeForNew()
        this.setLoading(false)
      }
    },
    addNumberPage () {
      this.$router.replace(`/member/lottery/yeeki/${this.catalogId}/${this.roundId}`)
    }
  }
}
</script>

<style>
</style>
